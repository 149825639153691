.dashboard {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  /* font-family: Arial, sans-serif; */
}

.chart-container, .user-container {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: 5px;
  width: 100%;
}

.order-list {
  list-style-type: none;
  padding: 0;
}

.order-list li {
  margin: 5px 0;
}

.user-stats {
  display: flex;
  flex-direction: column;
}

.user-stat {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.state-name {
  flex: 1;
}

.progress-bar {
  flex: 3;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 10px;
}

.progress {
  height: 10px;
}

.percentage {
  flex: 1;
  text-align: right;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.user-stats-section, .not-contacted-leads-section {
  flex: 1;
}