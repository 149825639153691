.header-img-left, .header-img-right {
  max-height: 150px; /* Adjust as needed */
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-header .text-center {
  flex-grow: 1;
  text-align: center;
}

.card-header .header-img-left {
  max-height: 150px; /* Adjust as needed */
  margin-right: auto;
}

.card-header .header-img-right {
  max-height: 150px; /* Adjust as needed */
  margin-left: auto;
}

.card-body .form-view-label {
  display: block;
  margin-bottom: 0.5rem;
}

.mt-3 {
  margin-top: 1rem !important;
}

.vh-100 {
  height: 100vh !important;
}

.d-flex {
  display: flex !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.w-100 {
  width: 100% !important;
}

.green-tick {
  font-size: 50px; /* Increase the size of the tick mark */
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}