.progress-container {
    display: flex;
    align-items: baseline;
    justify-content: center;
    overflow-x: auto; 
  }
  
  .progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  
  .progress-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  
  .step-number {
    font-weight: bold;
  }
  
  .progress-circle.active {
    background-color: #007bff; 
    animation: blink 1s infinite alternate;
  }
  
  .progress-circle.completed {
    background-color: #5cb85c; 
  }
  
  .progress-label {
    text-align: center;
    font-size: 14px;
    margin-top: 5px; 
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.5;
    }
  }
  
  /* Add this to your CSS file */
@keyframes sparkle {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* .sparkle {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: gold;
    border-radius: 50%;
    animation: sparkle 0.5s infinite;
    opacity: 0;
  }
  
  .sparkle.active {
    opacity: 1;
  }
   */