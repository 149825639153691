
.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 20px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}

#sidebar {
    min-width: 200px;
    max-width: 200px;
    background: #f1f1f9;
    color: #a9b7d0;
    transition: all 0.3s;
}
#minisidebar{
    min-width: 90px;
    max-width: 90px;
    background: #1a293b;
    color: #a9b7d0;
    transition: all 0.3s;
}
#sidebar.active {
    display: none;
}
#minisidebar.active{
    display: none;
}

#sidebar .sidebar-header, #minisidebar .sidebar-header{
    padding: 20px;
    background: #f1f1f9;
}




#sidebar ul li a, #minisidebar ul li a {
    padding: 10px;
    font-size: 1em;
    display: block;
    text-decoration: none;
    color: #59595d;
    padding-left: 1rem;
}

#sidebar ul li a:hover,#minisidebar ul li a:hover {
    color: #fff;
    background: #954040a6;
    
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background: #8a81dd;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content { 
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar {
        margin-left: -250px;
    }
    #sidebar.active {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
}
.minisidebar-header{
    width: 91px;
}