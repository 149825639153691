.bg-body-header{
  background-color : #ffffff;
}
.nav-link, .navbar-brand{
  color: #1a1b1b !important;
  font-size: 1rem;
}

.nav-link{
  margin-left: 1.1rem !important;
  /* padding-right: 0rem !important; */
  /* font-weight: 600; */
  font-size: medium;
  /* font-family: serif; */
}

.nav-link:hover, .nav-link.active{
  color: #000000 !important;
}

.nav-link.active{
  border-bottom: 5px solid #1a293b !important;
}

.breadcrum.nav-link{
  color: #a80b0b !important;
}

/* Notification */

.notification-container {
  position: relative;
  display: inline-block;
}

.notification-container img {
  width: 52px; /* Adjust size as needed */
  mix-blend-mode: hard-light;
}

.notification-area{
position: absolute;
top: 5rem;
z-index: 99;
max-height: 293px;
background-color: #fff;
padding: 10px 10px;
overflow: scroll;
-ms-overflow-style: none;
scrollbar-width: none;  
right: 1px;  
}

