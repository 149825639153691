
* {
  /* font-family: 'Merriweather', serif; */
  font-size: .9rem;
}

a{
  text-decoration: none !important;
}

body{
  background: #F4F5FA !important;
  
}

.maplabel{
  font-weight: bold;
  font-size: .75rem !important;
}

.fa-file-pdf.file-thumbnail{
  color: tomato;
}

.fa-file-word.file-thumbnail{
  color: #0d6efd;
}

.fa-file-excel.file-thumbnail{
  color: #198754;
}


footer {
  background-color: #647393;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
}

footer a{
  text-decoration: none;
}

.header{
  background: none;
}
.header, .header a{
  color: #161e2c
}

.sidebar-header{
  color:#fff;
}

.btn-floating{
  border-radius: 50% !important;
}

.field-label{
  font-size: smaller;
}
.field-data{
  border-bottom: 1px solid #ccc;
  
}

.related-list-table a{
  text-decoration: none;
}

.related-list-table th{
  font-weight: normal;
  text-transform: uppercase;
}

.btn-primary,.btn-info {
  background-color: #5f65f0 !important;
  color: #fff !important;
  border-color: #5f65f0 !important;
}

.login-form{
  background-color: #fff;
  width: 100%;
  box-shadow: 0 1px 20px 0 rgb(69 90 100 / 8%);
  margin-top:5rem;
}

.login-form i{
  color: #f7981c;

}
.login-section{
  border-left: 1px solid #ccc;
}

.login-form img{
  width: 100%;
}

.login-form .vr{
  border-right: 1px solid #ccc;
}
.data-table a{
  text-decoration: none;
}

.data-table > tbody > tr:nth-child(2n+1) > td, .data-table > tbody > tr:nth-child(2n+1) > th {
  background-color: #d1edff  !important;
}

.data-table th{
  /* /* background-color: #fff !important; */
    /* color: #ffffff !important;  */
    background-color: #1a293b !important;
    color: #fff !important;
    border: 1px solid #1a293b;
    border-top: 3px solid #4B84BC;
    font-weight: 400;
    text-transform: uppercase;
    white-space: nowrap;
}

.data-table{
  border: 1px solid #91969E;
  border-radius: 5px;
}

.view-form label{
  font-size: smaller;
  display: block;
  text-transform: uppercase;
  margin-top: 1rem;
}

.view-form-header{
  /* background-color: #C0373A;
  color: #ffffff; */
  background-color: #1a293b;
  color: #fff;
  padding: .5rem;
  border-top: 3px solid #878889ed
}

.view-form-header{
  /* background-color: #C0373A;
  color: #ffffff; */
  background-color: #1a293b;
  color: #fff;
  padding: .5rem;
  border-top: 3px solid #878889ed;
}

.view-form-header-lead{
  /* background-color: #C0373A;
  color: #ffffff; */
  background-color: #1a293b;
  color: #fff;
  padding: .5rem;
  border-top: 3px solid #878889ed;
  width: 155%;
}
.form-detail, .form-edit {
  border: 1px solid #ccc;
  padding-bottom: 1rem;
  background-color: rgba(255,255,255,.75);
}

.view-form span{
  display: block;
  border-bottom: 1px solid #ccc;
  
}

.fl{
  padding-left: 0px !important;
}
.fr{
  padding-right: 0px !important;
}

.data-table {
  font-size: smaller;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  

}
input[type=number].no-spinner::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
     margin: 0; 
}
.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
  
}

.myTable {
  width: 800px;
}

.fc-event{
  cursor: pointer;
}
table.fc-col-header{
  background-color:#161e2c;
}

table.fc-col-header a{
  color: #fff !important;
}
th.fc-col-header-cell{
  padding: .5rem 0 .5rem 0 !important;
  /*border: 1px solid #161e2c !important;*/
}

.btn-outline-primary{
  border: 1px solid  !important;
    color: #333 !important;
    

}

.btn-outline-primary:hover{
  background-color: #91969E !important;
  color: #fff !important;
}

.btn-custom{
  border: 1px solid #1c3df7 !important;
    color: #fff !important;
    background: #1c3df7 !important;
}

/*
 * Progressbar
 */

 .bar {
  --d: 1rem;       /* arrow depth */
  --gap: 0.3rem;   /* arrow thickness, gap */

  display: flex;
  margin-right: var(--d);
}

.bar-step {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.6rem var(--d);
  margin-right: calc(var(--d) * -1 + var(--gap));
  background: #e9262d;
  color: #ffffff;
  clip-path: polygon(
    0% 0%,
    calc(100% - var(--d)) 0%,
    100% 50%,
    calc(100% - var(--d)) 100%,
    0% 100%,
    var(--d) 50%
  );
}

.bar-step:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - var(--d)) 0%,
    100% 50%,
    calc(100% - var(--d)) 100%,
    0% 100%
  );
}


.bar-step.active {
  background: #23468c;
  color: #fff;
}

.bar-step.completed {
  background: rgb(79,167,86);
  color: #fff;
}

.ibs-edit-form{
  background-color: #fff;
  margin-bottom: 1rem;
}

.ibs-edit-form-lead{
  background-color: #fff;
  margin-bottom: 1rem;
  width:  154%;
}
.card-header{
  /* background-color: #C0373A  !important; */
 background-color: #474d57;
  color: #fff;
}

.nav-link[aria-selected=false]{
  color: #060606 !important;
}
.nav-link:hover, .nav-link:focus{
  color: #333 !important;
}
.nav-link{
  color: #fff;
}

.section-header{
  background-color: #474d57;
  color: #fff;
  padding: .5rem;
  margin-top: 1rem !important;
  text-transform: uppercase;
}
.form-select[required]{
  border-left: 4px solid red;
}

.Select[required] {
  border-left: 4px solid red;
}

input.form-control[required]{
  border-left: 4px solid red;
}

input.form-control[required]{
  border-left: 4px solid red;
}

textarea.form-control[required]{
  border-left: 4px solid red;
}

.form-select[required]{
  border-left: 4px solid red;
} 

.custom-select{
  border-left: 4px solid red;
  border-radius: 5px; 
  /* Added    border-radius by shivani*/
}


/* Added by shivani 9 aug */
.notify-number {
  background: tomato;
  width: 18px;
  height: 18px;
  margin: 0;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -5px;
  /* padding: 3px; */
  font-size: 0.8rem;
  color: #fff;
  font-weight: bold;
  text-align: center;
}


._3ybTi {
  display: inline;
}

.mobilecard{
  display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-right: 1rem;
}

.mobilecard span, .mobilecard a{
  width: 50%;
  padding-bottom: .7rem;
}

.card-header-tabs .nav-link.active{
  color: #000000 !important;
  
}

.badge{
  font-weight: normal !important;
  font-size: 1rem !important;
}
.kanban-box {
  background: #fff;
  padding: 1rem;
  margin: .5rem 0 .5rem 0;
  border: 1px solid #e6e6e6;
  text-align: left;
}

.kanban-box span {
  font-size: .8rem;
  
}

.kanban-box p {
  padding-top: .5rem;
  font-size: .7rem;

}

.kanban-box .data a {
  font-size: .8rem;
}

.kanban-column-title div{
  font-size: .9rem;
  background-color: #004e7e;
  color: #fff;
  padding: .5rem;
  text-align: center;
}
.kanban-column-title{
padding: 0 !important;
margin-right: .2rem;
margin-left: .2rem;
margin: 0;
}

.kanban-column{
  background: #ffffff;
    /* border-right: 1px solid #ccc; */
  padding: 0;
  margin-left: .2rem;
  margin-right: .2rem;
  margin:0;
  border-right: 1px solid #e6e6e6;
}

.kanban-box .badge{
  font-size: .7rem !important;
}

.user-avatar {

    width: 24px;
    height: 24px;
    border-radius: 50%;
    font-size: .7rem;
    line-height: 24px;
    text-align: center;    
    background: #6c757dd9;
    color: #fff;
}

.user-avatar-span {
  padding: 3px;
  border-radius: 50%;
  font-size: .7rem !important;
  line-height: 24px;
  text-align: center;    
  background: #6c757dd9;
  color: #fff;
}


/* .container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 3.5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto;
} */
.canvasjs-chart-credit {
  visibility: hidden !important;
  outline: none ;
  margin: 0px;
  position: absolute;
  right: 2px;
  top: 386px;
  color: dimgrey;
  text-decoration: none;
  font-size: 11px;
  /* font-family: Calibri, "Lucida Grande", "Lucida Sans Unicode", Arial, sans-serif; */
}



/* Lead Status UI CSS */
.scrollable-container {
  max-height: 400px;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .scrollable-container {
    max-height: none;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
      max-width: 100% !important;
  }
}

/* Header.css */
.navbar-nav #khaki-brown {
  color: #ffe600 !important; /* Khaki */
}

.sagegreen {
  color: #9DC183 !important; /* Sage Green */
}

.goldenrod {
  color: #DAA520 !important; /* Goldenrod */
}

.sienna {
  color: #A0522D !important; /* Sienna */
}

.shadowpurple {
  color: #9370DB !important; /* Shadow Purple */
}

.firebrick {
  color: #B22222 !important; /* Firebrick */
}

.mediumaquamarine {
  color: #66CDAA !important; /* Medium Aquamarine */
}

.active {
  border-bottom: 3px solid #244c7e;
}
  

/* Add By shivani start */
/* SpinnerOverlay.css */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add By shivani end */

/* //End here */


.notification-area{
position: absolute;
top: 5rem;
z-index: 99;
max-height: 293px;
background-color: #fff;
padding: 10px 10px;
overflow: scroll;
-ms-overflow-style: none;
scrollbar-width: none;
}

#home {
  color: #6D9ACF !important;
}

#contacts {
  color: #A68D60 !important; /* Khaki Brown */
}

#leads {
  color: #77966D !important; /* Sage Green */
}

#products {
  color: #8E7A6B !important; /* Taupe */
}

#projects {
  color: #DAA520 !important; /* Goldenrod */
}

#users {
  color: #A0522D !important; /* Sienna */
}

#projectdashboard {
  color: #6A5D7B !important; /* Shadow Purple */
}

#reports {
  color: #B22222 !important; /* Firebrick */
}

#archives {
  color: #66CDAA !important; /* Medium Aquamarine */
}

#repairticket {
  color : #77966D !important
}
